import { getBankList } from "../services/Core";
import { IBank } from "../types/IBank";
import { extractReponse } from "./responseExtractor";

export const loadBankListUntilEmpty = async () => {
  let bankList: IBank[] = [];
  let startFrom = 0;

  const loadBankList = async () => {
    const responses = await Promise.all(
      Array.from({ length: 10 })
      .map((item, index) => getBankList({ page: startFrom + index + 1 }))
    );

    const bankListArray: Array<IBank[]> = responses.map((response) => extractReponse(response))

    const isLastResponseEmpty = !bankListArray[bankListArray?.length - 1]?.length;

    bankList = bankList.concat(bankListArray.reduce((result, bankList) => {
      if (!bankList) {
        return result;
      }
      return result.concat(bankList);
    }, []));

    if (!isLastResponseEmpty) {
      startFrom = startFrom + 10;
      await loadBankList();
    }
  };

  await loadBankList();

  return bankList;
};
