/** @jsxImportSource @emotion/react */
import { Box, Button, Card, Grid, Select, Stack, MenuItem, FormControl, InputLabel, Input, TextField, Link } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GridColDef } from '@mui/x-data-grid';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
	APP_TITLE,
	DATE_FORMAT,
	DISPLAY_DATE_TIME_FORMAT,
	RESET,
	SEARCH,
	REFRESH,
  PAYMENT_METHOD_LABEL,
  RECON_FILTER_STATUSES,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE,
} from '../../utils/constants';
import { PAGE_TITLE, FIND_ACCOUNT_IDENTIFIER } from './constants';
import Logger from '../../services/Logger';
import sharedDataAtom from '../../recoil/atoms/sharedDataAtom';
import { formatDecimal } from '../../utils/formatCurrency';
import { Refresh } from '@mui/icons-material';
import { DownloadReport } from '../../components/Actions/DownloadReport';
import pageStateAtom from '../../recoil/atoms/pageStateAtom';
import { ITimeFilterRef, TimeFilter } from '../../components/Filter/TimeFilter';
import { ActionItem } from '../../components/Actions/ActionItem';
import moment from 'moment';
import { CustomDataGrid } from '../../components/DataGrid/CustomDataGrid';
import { Search } from '../../components/Filter/Search';
import { CustomColumnHeader } from '../../components/DataGrid/CustomColumnHeader';
import { DownloadReportResultType } from '../../components/Actions/DownloadReport/types';
import { CurrencyCode } from '../../types/Currency';
import { useLocation } from 'react-router-dom';
import { FilterEnum } from '../../components/Filter/TimeFilter/constants';
import { IAccountBalanceHistory, IAccountBalanceSummary, IGetAccountBalanceHistoryQueryParameters, getAccountBalanceHistory, getAccountBalanceSummary } from '../../services/Balance';
import { extractReponse } from '../../utils/responseExtractor';
import { useDownloadHandler } from '../../hooks/useDownloadHandler';
import { trimEmptyField } from '../../utils/trimEmptyField';
import { IPaymentLinkRecon, IPaymentLinkReconQuery, IPaymentLinkReconSummary, IPaymentLinkSummary,PaymentMethodTyped, getPaymentLinkRecon, getPaymentLinkReconSummary } from '../../services/Payment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const PAYMENT_METHOD: {
  [key in typeof PaymentMethodTyped[number]]: string
} = {
  'bank_transfer': 'Bank transfer',
  'credit_card': 'Credit card',
  'domestic_card': 'Domestic card',
  'ewallet_galaxypay': 'Galaxypay',
  'ewallet_momo': 'Momo',
  'ewallet_viettel': 'Viettel',
  'ewallet_zalopay': 'Zalopay',
  'qrpay': 'QR',
};

const INITIAL_VALUE: {
  paymentMethod: keyof typeof PAYMENT_METHOD | '';
  startDate: moment.Moment;
  endDate: moment.Moment;
  orderID: string;
  status: string;
} = {
  paymentMethod: '',
  startDate: moment().startOf('month'),
  endDate: moment(),
  orderID: '',
  status: '',
};

const columns: GridColDef<IPaymentLinkRecon & { index: number }>[] = [
  {
		field: 'reconciled_at',
		headerName: 'Thời gian đối soát',
		flex: 1,
    minWidth: 180,
    valueFormatter(params) {
      if (params.value) {
        return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
      }

      return '';
    },
	},
	{
		field: 'transaction_at',
		headerName: 'Ngày thanh toán',
		flex: 1,
    minWidth: 200,
    valueFormatter(params) {
      if (params.value) {
        return moment(params.value).format(DISPLAY_DATE_TIME_FORMAT).toString();
      }

      return '';
    },
	},
  {
		field: 'payment_number',
		headerName: 'Mã thanh toán',
		flex: 1,
    minWidth: 300,
	},
  {
		field: 'transaction_amount',
		headerName: 'Số tiền',
		flex: 1,
    minWidth: 200,
    valueFormatter(params) {
      if (params.value) {
        return formatDecimal(params.value);
      }

      return '';
    },
	},
  {
		field: 'currency_code',
		headerName: 'Đơn vị',
		align: 'center',
		headerAlign: 'center',
		valueFormatter(params) {
			return CurrencyCode[params.value as keyof typeof CurrencyCode];
		},
	},
  {
		field: 'transaction_status',
		headerName: 'Trạng thái thanh toán',
		flex: 1,
    minWidth: 300,
    valueFormatter(params) {
      if (params.value === 'success') {
        return 'Thành công';
      }

      return 'thất bại';
    }
	},
  {
		field: 'provider_code',
		headerName: 'Cổng thanh toán',
		flex: 1,
    minWidth: 180,
    valueFormatter(params) {
      return 'GALAXY PAY'
    },
	},
  {
		field: 'payment_method',
		headerName: 'Phương thức thanh toán',
		flex: 1,
    minWidth: 180,
    valueFormatter(params) {
      if (params.value && PAYMENT_METHOD_LABEL[params.value as typeof PaymentMethodTyped[number]]) {
        return PAYMENT_METHOD_LABEL[params.value as typeof PaymentMethodTyped[number]];
      }

      return '';
    },
	},
  {
		field: 'payment_source_type',
		headerName: 'Loại thẻ',
		flex: 1,
    minWidth: 180,
	},
  {
		field: 'payment_source_account',
		headerName: 'Số thẻ',
		flex: 1,
    minWidth: 180,
	},
  {
		field: 'result_status',
		headerName: 'Kết quả đối soát',
		flex: 1,
    minWidth: 180,
    valueFormatter(params) {
      const labelMapByValue = {
        'NULL': 'Chờ đối soát',
        'failed': 'Không thành công',
        'success': 'Thành công'
      };

      return labelMapByValue[params.value as keyof typeof labelMapByValue];
    }
	},
  {
    field: 'reconcile_status',
    headerName: 'Trạng thái đối soát',
    minWidth: 160,
    valueFormatter(params) {
      if (params.value === null) {
        return 'Chờ đối soát'
      };

      if (params.value === '00') {
        return 'Khớp';
      }

      return 'Không khớp';
    },
  },
];

const mapValueToRequestPayload = (values: typeof INITIAL_VALUE) => {
  const { paymentMethod, startDate, endDate, orderID, status } = values;

  const generateStatusValue = () => {
    switch (status) {
      case 'matched':

        return {
          status: '00',
        };

      case 'unmatched':
        return {
          not: '00'
        };

      case 'notReconYet':
        return {
          status: 'NULL',
        };

      default:
        return {};
    }
  };

  const payload: {
    status?: IPaymentLinkReconQuery['status'] | '';
    start?: IPaymentLinkReconQuery['start'] | '';
    end?: IPaymentLinkReconQuery['end'] | '';
    method?: IPaymentLinkReconQuery['method'] | '',
    number?: IPaymentLinkReconQuery['number'] | '',
    ref?: IPaymentLinkReconQuery['ref'] | '',
    link?: IPaymentLinkReconQuery['link'] | '',
  } = {
    method: paymentMethod,
    start: startDate.format(DATE_FORMAT).toString(),
    end: endDate.format(DATE_FORMAT).toString(),
    number: orderID,
    ...generateStatusValue(),
  };

  return trimEmptyField<IPaymentLinkReconQuery>(payload);
};

const mapListRequestPayloadToDownloadRequestPayload = (values: any) => {
  const {
    acc,
    bank,
    status,
    cif,
    type,
    start,
    end,
    date,
    sme,
  } = values;

  const ifUserChoiceDateRange = (_start: string, _end: string) => _start && _end;
  const today = moment().format(DATE_FORMAT).toString();
  
  return {
    account_number: acc,
    bank_code: bank,
    aggregated_status: status,
    cif_type: type,
    cif_number: cif,
    sme_number: sme,
    start_date: start || undefined,
    end_date: end || undefined,
    occurring_date: date || (ifUserChoiceDateRange(start, end) ? undefined : today),
  };
}

export const PaymentRequestRecon = () => {	
	const [pageState, setPageState] = useRecoilState(pageStateAtom);
	const [searchParams, setSearchParams] = useSearchParams();
  const [paymentLinks, setPaymentLinks] = useState<IPaymentLinkRecon[]>([])
  const [summary, setSummary] = useState<IPaymentLinkReconSummary | null>(null)
	const [isLoading, setIsLoading] = useState(true);
  const timeFilterRef = useRef<ITimeFilterRef>({ reset() {}, });
	const [paginationModel, setPaginationModel] = useState({
		pageSize: searchParams.get('limit') ? Number(searchParams.get('limit')) : DEFAULT_PAGE_SIZE,
		page: searchParams.get('page') ? Number(searchParams.get('page')) - 1 : DEFAULT_PAGE,
	});
  const isApplyingFilterValue = useRef(searchParams.get('isApplyingFilterValue') || false);
  const exportAndDownload = useDownloadHandler();

	const fetchData = useCallback(
		async (params: IPaymentLinkReconQuery) => {
			try {
				setIsLoading(true);

				const response = await getPaymentLinkRecon(params);
        const summaryResponse = await getPaymentLinkReconSummary(params);
        const paymentLinks = extractReponse<IPaymentLinkRecon[]>(response);
        const summary = extractReponse<IPaymentLinkReconSummary[]>(summaryResponse)

        setPaymentLinks((paymentLinks || []).map((paymentLink, index) => ({
          ...paymentLink,
          index: (params.page! * index) + 1,
        })));
        setSummary(summary?.[0]);
			} catch (exception) {
				Logger.log(exception);
			} finally {
				setIsLoading(false);
			}
		},
		[],
	);

	const formik = useFormik<typeof INITIAL_VALUE>({
		initialValues: {
      startDate: searchParams.get('startDate') ? moment(searchParams.get('startDate')) : INITIAL_VALUE.startDate,
      endDate: searchParams.get('endDate') ? moment(searchParams.get('endDate')) : INITIAL_VALUE.endDate,
      paymentMethod: (searchParams.get('paymentMethod') || INITIAL_VALUE.paymentMethod) as  typeof INITIAL_VALUE.paymentMethod,
      orderID: searchParams.get('orderID') || INITIAL_VALUE.orderID,
      status: searchParams.get('status') || INITIAL_VALUE.status,
    },
		onSubmit: () => {
      isApplyingFilterValue.current = true;
			onChangePage({
				page: 0,
				pageSize: 10,
			});
		},
	});

	useEffect(() => {
		setPageState({
			...pageState,
			pageTitle: PAGE_TITLE,
		});
		fetchData({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        end: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString()
      }),
		});
    setSearchParams(trimEmptyField({
			page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        endDate: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      })
		}));
	}, [fetchData]);

	const onChangePage = (nextPaginationModel: typeof paginationModel) => {
		setPaginationModel(nextPaginationModel);

		fetchData({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      ...(isApplyingFilterValue.current ? mapValueToRequestPayload(formik.values) : {
        start: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        end: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      }),
		});
		setSearchParams(trimEmptyField({
			page: nextPaginationModel.page + 1,
      limit: nextPaginationModel.pageSize,
      isApplyingFilterValue: isApplyingFilterValue.current,
      ...(isApplyingFilterValue.current ? formik.values : {
        startDate: INITIAL_VALUE.startDate.format(DATE_FORMAT).toString(),
        endDate: INITIAL_VALUE.endDate.format(DATE_FORMAT).toString(),
      })
		}));
	};

	const handleDownloadReport = () => {
    alert('API chưa support');
    // exportAndDownload({
    //   reportConditions: (isApplyingFilterValue.current ? mapListRequestPayloadToDownloadRequestPayload(mapValueToRequestPayload(formik.values)) : {
    //     occurring_date: moment().format(DATE_FORMAT).toString(),
    //   }),
    //   reportType: 'va_balance_history',
    // });
	};

	const handleFilterReset = () => {
		formik.setValues(INITIAL_VALUE);
    isApplyingFilterValue.current = false;
    timeFilterRef.current.reset();
    onChangePage({
      page: 0,
      pageSize: 10,
    });
	}

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{PAGE_TITLE} | {APP_TITLE}
					</title>
				</Helmet>
			</HelmetProvider>
			<Card sx={{ padding: 2, paddingTop: 3 }}>
				<Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(4, 1fr)' }}>
          <FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>Phương thức thanh toán</InputLabel>
						<Select
              labelId='status-selection'
              autoWidth
              label="Phương thức thanh toán"
              {...formik.getFieldProps('paymentMethod')}
            >
							{Object.entries(PAYMENT_METHOD).map(([value, label]) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl size='small' sx={{ minWidth: 150 }}>
						<InputLabel id='status-selection'>Trạng thái đối soát</InputLabel>
						<Select labelId='status-selection' autoWidth label='Trạng thái đối soát' {...formik.getFieldProps('status')}>
							<MenuItem value=''>
								<em>Tất cả</em>
							</MenuItem>
							{Object.entries(RECON_FILTER_STATUSES).map(([value, label]) => (
								<MenuItem key={value as string} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
          <FormControl sx={{ minWidth: 150 }}>
						<TextField fullWidth size='small' label="Mã thanh toán" {...formik.getFieldProps('orderID')} />
					</FormControl>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
								format={DATE_FORMAT}
								label="Ngày bắt đầu"
								value={formik.values.startDate}
								onChange={(value) => formik.setFieldValue('startDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
          <FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								maxDate={moment()}
                minDate={formik.values.startDate}
								format={DATE_FORMAT}
								label="Ngày kết thúc"
								value={formik.values.endDate}
								onChange={(value) => formik.setFieldValue('endDate', value)}
								slotProps={{ textField: { size: 'small' } }}
							/>
						</LocalizationProvider>
					</FormControl>
					{/* <TimeFilter
            ref={timeFilterRef}
            enableDateRangeForCustomOption
            handleFilterOptionChange={() => {}}
            initialValue={formik.values.selectedTimeFilter as FilterEnum.Today}
          /> */}
				</Box>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(1, 1fr)',
						gap: 1,
						mt: 1,
					}}>
					<Stack direction='row' spacing={2} sx={{ pt: 1, pb: 1 }} alignItems='right' justifyContent={'flex-end'}>
						<Button variant='outlined' sx={{ mr: 2 }} onClick={handleFilterReset}>
							{RESET}
						</Button>
						<Button variant='contained' onClick={formik.submitForm}>
							{SEARCH}
						</Button>
					</Stack>
				</Box>
			</Card>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mb: 2,
					mt: 2,
				}}>
        <Box />
				<Box>
					<DownloadReport
						handleDownloadReport={handleDownloadReport}
					/>
					<ActionItem title={REFRESH} icon={Refresh} onClick={() => onChangePage(paginationModel)} />
				</Box>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Card>
						<CustomDataGrid
							tableKey='PAYMENT_LINK_TABLE'
							loading={isLoading}
							rowCount={summary?.total_transaction || 0}
							rows={paymentLinks}
							columns={columns}
							getRowId={(row: IPaymentLinkRecon) => row.payment_link_number}
							paginationModel={paginationModel}
							onPaginationModelChange={onChangePage}
              pageSizeOptions={[10, 50, 100]}
							summaryRows={[
                { key: 'total_ammount', value: formatDecimal(summary?.total_amount || 0) },
								{ key: 'total_transaction', value: formatDecimal(summary?.total_transaction || 0) },
              ]}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default PaymentRequestRecon;
